import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>

                <p><strong>Effective Date:</strong> 01.09.2024</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">1. Introduction</h2>
                <p>Welcome to Snapshine ("we," "our," or "us"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application (the "App"). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the App.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">2. Information We Collect</h2>

                <h3 className="text-xl font-bold mt-4 mb-2">2.1 Personal Information</h3>
                <p>When you register to use Snapshine or subscribe to our services, we may collect personal information such as:</p>
                <ul className="list-disc list-inside ml-4">
                    <li><strong>Email address:</strong> Used for account creation, authentication, and communication.</li>
                    <li><strong>Subscription data:</strong> Managed via RevenueCat, including the status of your subscription, billing cycle, and related payment details.</li>
                </ul>

                <h3 className="text-xl font-bold mt-4 mb-2">2.2 Usage Data</h3>
                <p>We collect information about how you access and use the App, including:</p>
                <ul className="list-disc list-inside ml-4">
                    <li><strong>Device information:</strong> Type of device, operating system, and app version.</li>
                    <li><strong>Log data:</strong> Your IP address, time and date of use, and other related data.</li>
                </ul>

                <h3 className="text-xl font-bold mt-4 mb-2">2.3 Analytics</h3>
                <p>We use third-party services like RevenueCat to help us understand the usage of our App. These services may collect information sent by your device as part of your use of the App.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">3. How We Use Your Information</h2>
                <p>We use the information we collect for the following purposes:</p>
                <ul className="list-disc list-inside ml-4">
                    <li><strong>To provide and maintain our service:</strong> Including processing your subscription and managing your account via RevenueCat.</li>
                    <li><strong>To improve our service:</strong> By analyzing usage patterns to enhance user experience.</li>
                    <li><strong>To communicate with you:</strong> Sending updates, newsletters, and responding to inquiries.</li>
                    <li><strong>To enforce our Terms of Service:</strong> Ensuring compliance with our policies.</li>
                </ul>

                <h2 className="text-2xl font-bold mt-6 mb-2">4. Sharing Your Information</h2>
                <p>We do not sell, trade, or rent your personal information to others. However, we may share your information with:</p>
                <ul className="list-disc list-inside ml-4">
                    <li><strong>Service providers:</strong> Such as RevenueCat, to manage subscriptions and process payments.</li>
                    <li><strong>Legal authorities:</strong> If required by law or to protect the rights, property, or safety of Snapshine or others.</li>
                </ul>

                <h2 className="text-2xl font-bold mt-6 mb-2">5. Security of Your Information</h2>
                <p>We take reasonable measures to protect your personal information. However, please note that no method of transmission over the internet, or method of electronic storage, is 100% secure.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">6. Your Data Protection Rights</h2>
                <p>Depending on your location, you may have the following rights regarding your personal information:</p>
                <ul className="list-disc list-inside ml-4">
                    <li><strong>Access:</strong> Request access to the information we hold about you.</li>
                    <li><strong>Correction:</strong> Request that we correct any inaccuracies in your information.</li>
                    <li><strong>Deletion:</strong> Request the deletion of your personal information, subject to certain exceptions.</li>
                </ul>

                <h2 className="text-2xl font-bold mt-6 mb-2">7. Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the updated policy on the App.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">8. Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                <p><strong>Email:</strong> alexnsbmr@gmail.com</p>

                <p className="mt-6">---<br /><strong>Last updated:</strong> 01.09.2024</p>
            </div>
        </>
    );
};

export default PrivacyPolicy;
// components/Header.tsx
import React from 'react';
import { appConfig } from '../appConfig';

const Header: React.FC = () => {
    return (
        <header className="bg-white shadow-md">
            <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
                <img src={appConfig.appLogoPath} alt="Logo" className="logo-class w-32 h-10" />
                {/* <div className="text-2xl font-bold text-purple-600">{appConfig.name}</div> */}
                <div className="space-x-4">
                    {/* <a href="#features" className="text-gray-700 hover:text-purple-600">Features</a> */}
                    <a href={appConfig.appStoreLink} className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700">Download</a>
                </div>
            </nav>
        </header>
    );
};

export default Header;
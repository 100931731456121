import React from "react";
import { appConfig } from "../appConfig";

interface ButtonProps {
    imageSrc: string;
    altText: string;
}

const MadeByButton: React.FC<ButtonProps> = ({ imageSrc, altText }) => {
    return (
        <a
            href={appConfig.socialXUrl}
            className="inline-flex items-center p-2 bg-gradient-to-r from-gray-900 to-gray-700 rounded-lg shadow-lg hover:opacity-90 border-2 border-white"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Follow ${appConfig.socialXUsername} on X`}
        >
            <img
                src={imageSrc}
                alt={altText}
                className="w-8 h-8 rounded-full mr-2"
            />
            <div className="text-left">
                <p className="text-white text-sm font-semibold">
                    Made by <span className="text-pink-400">{appConfig.socialXUsername}</span>
                </p>
                <p className="text-gray-300 text-xs">Follow me on X</p>
            </div>
        </a>
    );
};

export default MadeByButton;
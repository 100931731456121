import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import emailjs from 'emailjs-com';  // Import EmailJS

const Support: React.FC = () => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const templateParams = {
            to_name: 'Support Team',
            from_name: name,
            message: message,
            reply_to: email,
        };

        emailjs
            .send(
                'service_ppts8e2',
                'template_aamvmse',
                templateParams,
                'x8ee7dfD8qbdGNfBs'
            )
            .then(
                (response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    setSubmitted(true);
                },
                (err) => {
                    console.error('FAILED...', err);
                    setError('Failed to send message. Please try again later.');
                }
            );
    };

    return (
        <div className="max-w-lg mx-auto py-10 px-5">
            <h1 className="text-3xl font-bold text-center mb-5">Support</h1>
            <p className="text-center mb-8">
                If you have any questions or need help, please fill out the form below to contact us.
            </p>

            {submitted ? (
                <div className="bg-green-100 text-green-800 p-5 rounded-md mb-5">
                    <h2 className="text-xl font-bold">Thank you for contacting us!</h2>
                    <p>We will get back to you shortly.</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="space-y-5">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                        />
                    </div>

                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                        />
                    </div>

                    <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                        <textarea
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                            rows={4}
                        />
                    </div>

                    {error && <p className="text-red-600">{error}</p>}

                    <button
                        type="submit"
                        className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                        Submit
                    </button>
                </form>
            )}

            <hr className="my-10" />

            <h2 className="text-2xl font-semibold mb-4">Useful Links</h2>
            <ul className="mt-4 space-x-4">
                <div className="mt-4 space-x-4">
                    <NavLink to="/privacy-policy" className="hover:text-purple-400">Privacy Policy</NavLink>
                    <NavLink to="/terms-of-service" className="hover:text-purple-400">Terms of Service</NavLink>
                </div>
            </ul>
        </div>
    );
};

export default Support;
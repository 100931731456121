import Reviews from "./components/Reviews";

export const appConfig = {
    name: "snapshine",
    title: "snapshine",
    description: "Stunning screenshots for social media",
    appStoreRating: 4.8,
    totalReviews: 1700,
    reviewsTitle: "What our users are saying",
    appLogoPath: "/logo.svg",
    appIconPath: "/icon.png",
    avatarPath: "/avatar.png",
    featureTitle: "Screenshots",
    socialXUrl: "https://x.com/alexnsbmr",
    socialXUsername: "@AlexNsbmr",
    features: [
        { title: "One-Click Beautification", description: "Enhance your photos instantly with our smart AI" },
        { title: "Custom Filters", description: "Choose from a wide range of unique filters" },
        { title: "Easy Sharing", description: "Share your enhanced photos directly to social media" },
    ],
    reviews: [
        { name: "Sarah J.", text: "Snapshine transformed my screenshots into stunning posts for social media!", avatar: "/review_60.jpg" },
        { name: "Mike T.", text: "The templates are amazing! My Instagram posts have never looked better.", avatar: "/review_52.jpg" },
        { name: "Emma L.", text: "It's so easy to use! I can't stop enhancing my screenshots.", avatar: "/review_11.jpg" },
        { name: "Alex R.", text: "Really love this app. Easy to use!", avatar: "/review_29.jpg" },
        { name: "Olivia P.", text: "Snapshine makes sharing on social media effortless. 5 stars!", avatar: "/review_46.jpg" },
        { name: "Chris M.", text: "I've tried many screenshot enhancement apps, but Snapshine is the best!", avatar: "/review_76.jpg" }
    ],
    screenshotPaths: [
        '/screenshot1.jpg',
        '/screenshot2.jpg',
        '/screenshot3.jpg',
        '/screenshot4.jpg',
    ],
    descriptionTitle: "Description",
    descriptionText: `Edit and share beautiful screenshots effortlessly with snapshine!\n\nWhether you're a content creator, designer, or simply someone who loves to share visually appealing content on social media, snapshine is your ultimate tool for creating stunning screenshots that shine.\n\nWith intuitive controls and powerful features, it makes the process editing and sharing screenshots a breeze.`,
    callToActionTitle: "Ready to Shine?",
    callToActionText: "Download snapshine now and transform your screenshots!",
    appStoreLink: "https://apps.apple.com/us/app/id6627346969", // Replace with actual App Store link,
};
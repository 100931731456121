import React from 'react';
import { ReactComponent as AppStoreButtonSVG } from '../assets/app-store-button-badge.svg';
import { appConfig } from '../appConfig';

const Hero: React.FC = () => {
    return (
        <section className="container mx-auto px-6 py-16 flex flex-col md:flex-row items-center md:items-start">
            {/* App Icon */}
            <div className="mb-8 md:mb-0 md:mr-8 flex-shrink-0">
                <img
                    src={appConfig.appIconPath}
                    alt="App Icon"
                    className="w-32 h-32 md:w-48 md:h-48 rounded-2xl border border-gray-400 mx-auto md:mx-0"
                />
            </div>

            {/* Text and Buttons */}
            <div className="text-center md:text-left w-full md:w-auto">
                <h1 className="text-4xl md:text-6xl font-bold mb-4 text-gray-800">
                    {appConfig.title}
                </h1>
                <p className="text-xl mb-8 text-gray-600">
                    {appConfig.description}
                </p>
                <div className="flex justify-center md:justify-start">
                    <a href={appConfig.appStoreLink}>
                        <AppStoreButtonSVG className="w-32 h-12 md:w-40 md:h-16" />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Hero;
import React from 'react';
import { appConfig } from '../appConfig';

const Description: React.FC = () => {
    return (
        <section className="py-16 bg-gray-50">
            <div className="container mx-auto px-6">
                <h2 className="text-3xl font-extrabold text-left text-gray-800 mb-8">
                    {appConfig.descriptionTitle}
                </h2>
                <div className="whitespace-pre-line text-xl mb-8 text-gray-600">
                    {appConfig.descriptionText}
                </div>
            </div>
        </section>
    );
};

export default Description;
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService';
import Contact from './Pages/Support';

const App = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/terms-and-conditions" element={<TermsOfService />} />
                <Route path="/terms-of-use" element={<TermsOfService />} />
                <Route path='/support' element={<Contact />} />
            </Routes>
        </>
    );
};

export default App;
import React from 'react';
import { appConfig } from '../appConfig';

const Reviews: React.FC = () => {
    return (
        <section className="py-16 bg-gradient-to-r from-gray-100 to-gray-50">
            <div className="container mx-auto px-6">
                {/* <div className="flex justify-center items-center mb-12 bg-gray-900 text-white py-6 rounded-lg">
                    <div className="text-center">
                        <div className="text-4xl font-bold mb-2">{appConfig.appStoreRating}/5</div>
                        <div className="flex justify-center mb-2">
                            {[...Array(5)].map((_, i) => (
                                <svg key={i} className="w-6 h-6 fill-current text-yellow-400" viewBox="0 0 24 24">
                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                                </svg>
                            ))}
                        </div>
                        <div className="text-lg">{appConfig.totalReviews}+ Reviews</div>
                    </div>
                </div> */}
                <h2 className="text-3xl font-extrabold text-left text-gray-800 mb-8">
                    {appConfig.reviewsTitle}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                    {appConfig.reviews.map((review, index) => (
                        <div
                            key={index}
                            className="bg-white p-8 rounded-xl shadow-xl transform hover:-translate-y-2 transition-all duration-300 ease-in-out hover:shadow-2xl"
                        >
                            <div className="flex items-center mb-4">
                                <div className="text-yellow-400 flex space-x-1 mr-2">
                                    {[...Array(5)].map((_, i) => (
                                        <svg
                                            key={i}
                                            className="w-5 h-5 fill-current"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                                        </svg>
                                    ))}
                                </div>
                            </div>
                            <p className="text-gray-700 mb-6 italic leading-relaxed">
                                "{review.text}"
                            </p>
                            <div className="flex items-center">
                                <img
                                    src={review.avatar}
                                    alt={review.name}
                                    className="w-12 h-12 rounded-full shadow-sm mr-4 border-2 border-gray-200"
                                />
                                <div>
                                    <p className="text-gray-900 font-bold">
                                        {review.name}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Reviews;
import React from 'react';
import { appConfig } from '../appConfig';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Screenshots from '../components/Screenshots';
import Reviews from '../components/Reviews';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import Description from '../components/Description';

const Home: React.FC = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-purple-100 to-pink-100">
            <Header />
            <Hero />
            <Screenshots screenshots={appConfig.screenshotPaths} />
            <Description />
            <Reviews />
            <CallToAction link={appConfig.appStoreLink} />
            <Footer />
        </div>
    );
};

export default Home;
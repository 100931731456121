import React from 'react';
import { appConfig } from '../appConfig';

interface ScreenshotsProps {
    screenshots: string[];
}

const Screenshots: React.FC<ScreenshotsProps> = ({ screenshots }) => {
    return (
        <section className="py-16 bg-gray-100">
            <div className="container mx-auto px-6">
                <h2 className="text-3xl font-extrabold text-left text-gray-800 mb-8">
                    {appConfig.featureTitle}
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {screenshots.map((path, index) => (
                        <div
                            key={index}
                            className="group bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out transform hover:scale-105 hover:-translate-y-1 hover:shadow-xl"
                        >
                            <img
                                src={path}
                                alt={`App screenshot ${index + 1}`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Screenshots;
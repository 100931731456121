import React from 'react';
import { appConfig } from '../appConfig';
import { ReactComponent as AppStoreButtonSVG } from '../assets/app-store-button-badge.svg';

interface CallToActionProps {
    link: string;
}

const CallToAction: React.FC<CallToActionProps> = ({ link }) => {
    return (
        <section id="download" className="bg-purple-600 text-white py-16">
            <div className="container mx-auto px-6 text-center">
                <h2 className="text-3xl font-bold mb-4">{appConfig.callToActionTitle}</h2>
                <p className="text-xl mb-8">{appConfig.callToActionText}!</p>
                <div className="flex justify-center">
                    <a href={appConfig.appStoreLink}>
                        <AppStoreButtonSVG className="w-32 h-12 md:w-40 md:h-16" />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default CallToAction;

import React from 'react';

const TermsOfService: React.FC = () => {
    return (
        <>
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>

                <p><strong>Effective Date:</strong> September 1, 2024</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">1. Introduction</h2>
                <p>Welcome to Snapshine ("we," "our," or "us"). These Terms of Service ("Terms") govern your use of our mobile application (the "App"). By accessing or using the App, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the App.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">2. Eligibility</h2>
                <p>By using Snapshine, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms. If you are under 18, you may only use the App with the consent of a parent or legal guardian.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">3. Subscription and Billing</h2>
                <p>Snapshine offers subscription-based services managed through RevenueCat. By subscribing, you agree to pay the applicable subscription fees and any related charges. All payments are non-refundable, except as required by law. Subscriptions will automatically renew unless canceled before the renewal date.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">4. Use of the App</h2>
                <p>You agree to use Snapshine only for lawful purposes and in accordance with these Terms. You agree not to use the App to:</p>
                <ul className="list-disc list-inside ml-4">
                    <li>Violate any applicable laws or regulations.</li>
                    <li>Infringe upon or violate our intellectual property rights or the intellectual property rights of others.</li>
                    <li>Transmit any viruses, worms, or other malicious code.</li>
                    <li>Engage in any activity that could damage, disable, or impair the App or interfere with any other party's use of the App.</li>
                </ul>

                <h2 className="text-2xl font-bold mt-6 mb-2">5. Intellectual Property</h2>
                <p>The App and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio) are owned by Snapshine, its licensors, or other providers of such material and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">6. Termination</h2>
                <p>We may terminate or suspend your access to all or part of the App immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms. Upon termination, your right to use the App will immediately cease.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">7. Limitation of Liability</h2>
                <p>In no event shall Snapshine, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use the App; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the App; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our App by any third party; and/or (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the App, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">8. Governing Law</h2>
                <p>These Terms shall be governed and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">9. Changes to These Terms</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

                <h2 className="text-2xl font-bold mt-6 mb-2">10. Contact Us</h2>
                <p>If you have any questions about these Terms, please contact us at:</p>
                <p><strong>Email:</strong> alexnsbmr@gmail.com</p>

                <p className="mt-6">---<br /><strong>Last updated:</strong> September 1, 2024</p>
            </div>
        </>
    );
};

export default TermsOfService;
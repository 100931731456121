import React from 'react';
import { NavLink } from "react-router-dom";
import { appConfig } from '../appConfig';
import MadeByButton from './MadeByButton';

const Footer: React.FC = () => {
    return (
        <footer className="bg-gray-900 text-gray-300 py-12">
            <div className="container mx-auto px-6">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    <div className="md:col-span-2">
                        <h2 className="text-2xl font-bold text-white mb-4">{appConfig.name}</h2>
                        <p className="mb-4">{appConfig.description}</p>

                        <MadeByButton imageSrc={appConfig.avatarPath} altText={`Made by ${appConfig.socialXUsername}`} />
                    </div>
                    <div>
                        <h3 className="text-white text-lg font-semibold mb-4">Contact</h3>
                        <ul className="space-y-2">
                            <li><NavLink to="/support" className="hover:text-white transition-colors duration-200">Support</NavLink></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-white text-lg font-semibold mb-4">Legal</h3>
                        <ul className="space-y-2">
                            <li><NavLink to="/privacy-policy" className="hover:text-white transition-colors duration-200">Privacy Policy</NavLink></li>
                            <li><NavLink to="/terms-of-service" className="hover:text-white transition-colors duration-200">Terms of Service</NavLink></li>
                        </ul>
                    </div>
                </div>
                <div className="border-t border-gray-800 mt-8 pt-8 text-sm text-center">
                    <p>&copy; {new Date().getFullYear()} Snapshine. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;